import React from "react";
// import Helmet from "react-helmet";
import { graphql } from 'gatsby'

import '../components/App.css';

import Layout from '../components/layout'
import SEO from '../components/seo'
import tags from '../components/tags'
import Subscribe from "../components/subscribe"

import bg from '../images/forest.jpg'

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark;
  const post = posts[0].node
  const videoId = post.frontmatter.video;
  return (
    <Layout>
      <SEO title={post.frontmatter.title} keywords={tags} />
      <div className="page">
        <div className="page-content-container">

          <div className="page-content blog-page-content top-padding">
            <h1>{post.frontmatter.title}</h1>
          </div>

          {videoId && <div style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover' }} className="video-container">
            <div className="blog-page-content video-content">
              <div className="videoWrapper">
                <iframe title="Video" src={`https://www.youtube.com/embed/${videoId}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>}

          <div className="page-content blog-page-content">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>

          <Subscribe />

        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            video
          }
        }
      }
    }
  }
`;
